import { faExternalLinkAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Img from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BackgroundImage from 'gatsby-background-image';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReCAPTCHA from 'react-google-recaptcha';
import { Slide, toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import isEmail from 'validator/lib/isEmail';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import LocalizedLink from '../../components/LocalizedLink';

const MAX_MESSAGE_LENGTH = 1500;

export default class Freelance extends React.Component {
  constructor(props) {
    super(props);

    this.handleContactFormChange = this.handleContactFormChange.bind(this);
    this.handleRecaptchaToken = this.handleRecaptchaToken.bind(this);
    this.handleRecaptchaError = this.handleRecaptchaError.bind(this);
    this.handleRecaptchaExpired = this.handleRecaptchaExpired.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.recaptchaRef = React.createRef();
    this.inputFileRef = React.createRef();

    // When building page (static html) with Gatsby, window is not defined and we must skip the
    // following code.
    if (typeof window !== 'undefined') {
      // Only execute following code when we are in the browser (window is defined).
      window.recaptchaOptions = {
        useRecaptchaNet: true,
      };
    }

    this.state = {
      validated: false,
      isLoading: false,
      lastName: '',
      firstName: '',
      email: '',
      phone: '',
      cv: null,
      applicationType: '',
      availability: '',
      averageDailyRate: '',
      travel: '',
      remarks: '',
      validFirstName: true,
      validLastName: true,
      validEmail: true,
      validCV: true,
      invalidFormToastId: '',
    };
  }

  handleContactFormChange(event) {
    const { validated, invalidFormToastId } = this.state;

    const { target: { name, value } } = event;

    // if (name === 'modules' || name === 'applicationTypes') {
    //   const { options } = event.target;
    //   const values = [];
    //
    //   Object.values(options).forEach((option) => {
    //     if (option.selected) {
    //       values.push(option.value);
    //     }
    //   });
    //
    //   this.setState({
    //     [name]: values,
    //   }, () => {
    //     // Only if needed, we check the form validity.
    //     if (validated) {
    //       this.checkFormValidity();
    //     }
    //   });
    //
    //   return;
    // }

    // Test if a toast is displayed now for a validation error.
    // If it is the case, we close it.
    if (toast.isActive(invalidFormToastId)) {
      toast.dismiss(invalidFormToastId);
    }

    // Special handling for the CV file.
    if (name === 'cv') {
      this.setState({
        [name]: event.target.files[0],
      }, () => {
        // Only if needed, we check the form validity.
        if (validated) {
          this.checkFormValidity();
        }
      });

      return;
    }

    this.setState({
      [name]: value,
    }, () => {
      // Only if needed, we check the form validity.
      if (validated) {
        this.checkFormValidity();
      }
    });
  }

  handleRecaptchaToken(recaptchaToken) {
    // Waiting for a response.
    this.setState({
      isLoading: true,
    }, () => {
      // When recaptcha is ok, we can send the form values with the token.

      const { pathContext: { locale }, data: { markdownFileText: { frontmatter } } } = this.props;

      const {
        lastName,
        firstName,
        email,
        phone,
        cv,
        applicationType,
        availability,
        averageDailyRate,
        travel,
        remarks,
      } = this.state;

      const dataToSend = new FormData();
      dataToSend.append('recaptchaToken', recaptchaToken);
      dataToSend.append('lastName', lastName);
      dataToSend.append('firstName', firstName);
      dataToSend.append('email', email);
      dataToSend.append('phone', phone);
      dataToSend.append('cv', cv);
      dataToSend.append('applicationType', applicationType);
      dataToSend.append('availability', availability);
      dataToSend.append('averageDailyRate', averageDailyRate);
      dataToSend.append('language', locale);
      dataToSend.append('travel', travel);
      dataToSend.append('remarks', remarks);

      axios.post('https://europe-west1-datart-website.cloudfunctions.net/handleFreelanceForm',
        dataToSend, {})
        .then((response) => {
          // Captcha reinitialized.
          this.recaptchaRef.current.reset();

          toast.success(
            <p>
              {frontmatter.successToastPart1Text}
              <br />
              {frontmatter.successToastPart2Text}
            </p>, {
              className: 'rounded text-center',
            },
          );

          // Clear input file.
          this.inputFileRef.current.value = '';

          // Clear fields.
          this.setState({
            isLoading: false,
            lastName: '',
            firstName: '',
            email: '',
            phone: '',
            cv: null,
            applicationType: '',
            availability: '',
            averageDailyRate: '',
            travel: '',
            remarks: '',
          });
        })
        .catch((error) => {
          // Captcha reinitialized.
          this.recaptchaRef.current.reset();

          toast.error(
            <p>
              {frontmatter.errorToastPart1Text}
              <br />
              {frontmatter.errorToastPart2Text}
            </p>, {
              className: 'rounded text-center',
            },
          );

          this.setState({
            isLoading: false,
          });
        });
    });
  }

  handleRecaptchaError() {
    this.recaptchaRef.current.reset();
  }

  handleRecaptchaExpired() {
    this.recaptchaRef.current.reset();
  }

  checkFormValidity() {
    // We need to check the email (not empty and validity), the last name and first name
    // (not empty), the file (CV not empty), the profile (not empty), the modules (not empty) and
    // the application types (not empty).

    const {
      lastName,
      firstName,
      email,
      cv,
    } = this.state;

    // By default, all inputs are valid (and the form too).
    let validLastName = true;
    let validFirstName = true;
    let validEmail = true;
    let validCV = true;

    if (!lastName) {
      validLastName = false;
    }

    if (!firstName) {
      validFirstName = false;
    }

    if (!email || !isEmail(email, { allow_utf8_local_part: false })) {
      validEmail = false;
    }

    // Check for empty file with correct type and size.
    // Only pdf files are authorized with a max size of 9 Mo.
    if (!cv || cv.type !== 'application/pdf' || cv.size > (9 * 1024 * 1024)) {
      validCV = false;
    }

    this.setState({
      validLastName,
      validFirstName,
      validEmail,
      validCV,
    });

    // The form is invalid if one of its input is invalid.
    return !(!validLastName || !validFirstName || !validEmail || !validCV);
  }

  handleFormSubmit(event) {
    event.preventDefault();

    if (this.checkFormValidity() === false) {
      // Form is invalid (need to display validation).

      const { data: { markdownFileText: { frontmatter } } } = this.props;

      // Check if a toast is already displayed for a validation error (avoid to have multiple toast
      // at the same time for this error).
      const { invalidFormToastId } = this.state;

      let toastId = invalidFormToastId;

      if (!toast.isActive(toastId)) {
        toastId = toast.error(
          <p>
            {frontmatter.invalidFieldsToastPart1Text}
            <br />
            {frontmatter.invalidFieldsToastPart2Text}
          </p>, {
            className: 'rounded text-center',
          },
        );
      }

      this.setState({
        validated: true,
        invalidFormToastId: toastId,
      });
    } else {
      // Form is valid (no need to display validation anymore).
      // Execute recaptcha before sending form.
      this.setState({
        validated: false,
      }, () => this.recaptchaRef.current.execute());
    }
  }

  render() {
    const { location, data, pathContext: { locale, pageSlugs } } = this.props;
    const { markdownFileText: { frontmatter } } = data;

    const {
      validated,
      isLoading,
      lastName,
      firstName,
      email,
      phone,
      applicationType,
      availability,
      averageDailyRate,
      travel,
      remarks,
      validLastName,
      validFirstName,
      validEmail,
      validCV,
    } = this.state;

    // If we don't need validation, we keep default values to display nothing for validation.
    let isLastNameValid = false;
    let isFirstNameValid = false;
    let isEmailValid = false;
    let isPhoneValid = false;
    let isCVValid = false;
    let isApplicationTypeValid = false;
    let isAvailabilityValid = false;
    let isAverageDailyRateValid = false;
    let isTravelValid = false;
    let isRemarksValid = false;

    if (validated) {
      isLastNameValid = validLastName;
      isFirstNameValid = validFirstName;
      isEmailValid = validEmail;
      isPhoneValid = true;
      isCVValid = validCV;
      isApplicationTypeValid = true;
      isAvailabilityValid = true;
      isAverageDailyRateValid = true;
      isTravelValid = true;
      isRemarksValid = true;
    }
    
    return (
      <Layout
        location={location}
        locale={locale}
        pageSlugs={pageSlugs}
      >
        <SEO
          title={frontmatter.title}
          description={frontmatter.description}
          language={locale}
        />
        <BackgroundImage
          tag="div"
          className="d-table w-100 h-175-px"
          fluid={data.datArtJoinUsHeaderImage.childImageSharp.fluid}
        >
          <div className="d-table-cell align-middle text-center">
            <h1
              className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
            >
              {frontmatter.mainTitle}
            </h1>
          </div>
        </BackgroundImage>
        {/* <Container className="p-4 min-vh-100"> */}
        <Container className="p-4">
          <h2 className="pl-2 left-border">{frontmatter.secondaryTitle}</h2>
          <p>{frontmatter.introText}</p>
          <div className="bg-grey w-100 p-4 mx-auto mt-4 rounded special-shadow">
            <h3 className="text-info">{frontmatter.joinUsTitle}</h3>
            <ul>
              <li>{frontmatter.joinUsPart1Text}</li>
              <li>{frontmatter.joinUsPart2Text}</li>
              <li>{frontmatter.joinUsPart3Text}</li>
              <li>{frontmatter.joinUsPart4Text}</li>
              <li>{frontmatter.joinUsPart5Text}</li>
              <li>{frontmatter.joinUsPart6Text}</li>
            </ul>
          </div>
          {/* Temporary replace form by link to contact page */}
          <p className="text-center mt-5">
            <LocalizedLink to="/contact/#contact">
              <Button>{frontmatter.contactButtonText}</Button>
            </LocalizedLink>
          </p>
          {/* <Form */}
          {/*  noValidate */}
          {/*  onSubmit={this.handleFormSubmit} */}
          {/*  className="mt-5" */}
          {/* > */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formLastName"> */}
          {/*      <Form.Label>{frontmatter.formSurnameLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="text" */}
          {/*        name="lastName" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isLastNameValid} */}
          {/*        isInvalid={!validLastName} */}
          {/*        value={lastName} */}
          {/*      /> */}
          {/*      <Form.Control.Feedback type="invalid"> */}
          {/*        {frontmatter.formInvalidSurnameText} */}
          {/*      </Form.Control.Feedback> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formFirstName"> */}
          {/*      <Form.Label>{frontmatter.formFirstNameLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="text" */}
          {/*        name="firstName" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isFirstNameValid} */}
          {/*        isInvalid={!validFirstName} */}
          {/*        value={firstName} */}
          {/*      /> */}
          {/*      <Form.Control.Feedback type="invalid"> */}
          {/*        {frontmatter.formInvalidFirstNameText} */}
          {/*      </Form.Control.Feedback> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formEmail"> */}
          {/*      <Form.Label>{frontmatter.formEmailLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="email" */}
          {/*        placeholder={frontmatter.formEmailPlaceHolder} */}
          {/*        maxLength="254" */}
          {/*        name="email" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isEmailValid} */}
          {/*        isInvalid={!validEmail} */}
          {/*        value={email} */}
          {/*      /> */}
          {/*      <Form.Control.Feedback type="invalid"> */}
          {/*        {frontmatter.formInvalidEmailText} */}
          {/*      </Form.Control.Feedback> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formPhoneNumber"> */}
          {/*      <Form.Label>{frontmatter.formTelephoneLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="tel" */}
          {/*        name="phone" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isPhoneValid} */}
          {/*        value={phone} */}
          {/*      /> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formCV"> */}
          {/*      <Form.Label> */}
          {/*        {frontmatter.formCVLabel} */}
          {/*        <br /> */}
          {/*        {frontmatter.formCVFileTypeText} */}
          {/*        <br /> */}
          {/*        {frontmatter.formCVFileSizeText} */}
          {/*      </Form.Label> */}
          {/*      <Form.Control */}
          {/*        ref={this.inputFileRef} */}
          {/*        type="file" */}
          {/*        name="cv" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isCVValid} */}
          {/*        isInvalid={!validCV} */}
          {/*      /> */}
          {/*      <Form.Control.Feedback type="invalid"> */}
          {/*        {frontmatter.formInvalidCVFileText} */}
          {/*      </Form.Control.Feedback> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formApplicationType"> */}
          {/*      <Form.Label>{frontmatter.formJobLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="text" */}
          {/*        name="applicationType" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isApplicationTypeValid} */}
          {/*        value={applicationType} */}
          {/*      /> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formAvailability"> */}
          {/*      <Form.Label>{frontmatter.formAvailabilityLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="text" */}
          {/*        placeholder={frontmatter.formAvailabilityPlaceHolder} */}
          {/*        name="availability" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isAvailabilityValid} */}
          {/*        value={availability} */}
          {/*      > */}
          {/*      </Form.Control> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formAverageDailyRate"> */}
          {/*      <Form.Label>{frontmatter.formTJMLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="text" */}
          {/*        name="averageDailyRate" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isAverageDailyRateValid} */}
          {/*        value={averageDailyRate} */}
          {/*      > */}
          {/*      </Form.Control> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={8} controlId="formTravel"> */}
          {/*      <Form.Label>{frontmatter.formTravelLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        as="textarea" */}
          {/*        name="travel" */}
          {/*        rows="10" */}
          {/*        maxLength={MAX_MESSAGE_LENGTH} */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isTravelValid} */}
          {/*        value={travel} */}
          {/*      /> */}
          {/*      <Form.Text className="text-right"> */}
          {/*        {MAX_MESSAGE_LENGTH - travel.length} */}
          {/*        / */}
          {/*        {MAX_MESSAGE_LENGTH} */}
          {/*      </Form.Text> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={8} controlId="formRemarks"> */}
          {/*      <Form.Label>{frontmatter.formRemarksLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        as="textarea" */}
          {/*        name="remarks" */}
          {/*        rows="10" */}
          {/*        maxLength={MAX_MESSAGE_LENGTH} */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isRemarksValid} */}
          {/*        value={remarks} */}
          {/*      /> */}
          {/*      <Form.Text className="text-right"> */}
          {/*        {MAX_MESSAGE_LENGTH - remarks.length} */}
          {/*        / */}
          {/*        {MAX_MESSAGE_LENGTH} */}
          {/*      </Form.Text> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  {isLoading ? ( */}
          {/*    <Button type="submit" disabled className="mt-3"> */}
          {/*      <Spinner */}
          {/*        as="span" */}
          {/*        animation="border" */}
          {/*        size="sm" */}
          {/*        role="status" */}
          {/*        aria-hidden="true" */}
          {/*      /> */}
          {/*      <span className="sr-only">{frontmatter.formButtonSrOnly}</span> */}
          {/*    </Button> */}
          {/*  ) : ( */}
          {/*    <Button type="submit" className="mt-3">{frontmatter.formButtonText}</Button> */}
          {/*  )} */}
          {/* </Form> */}
          {/* <p className="small">{frontmatter.formRequiredFieldsText}</p> */}
          {/* <ReCAPTCHA */}
          {/*  ref={this.recaptchaRef} */}
          {/*  size="invisible" */}
          {/*  sitekey="6Ld7AtsUAAAAAL1YRmy6q660ymcsiFdiDIElubIY" */}
          {/*  onChange={this.handleRecaptchaToken} */}
          {/*  onErrored={this.handleRecaptchaError} */}
          {/*  onExpired={this.handleRecaptchaExpired} */}
          {/*  badge="inline" */}
          {/* /> */}
          {/* <ToastContainer */}
          {/*  position="bottom-center" */}
          {/*  autoClose={10000} */}
          {/*  closeOnClick={false} */}
          {/*  transition={Slide} */}
          {/* /> */}
        </Container>
      </Layout>
    );
  }
}

Freelance.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`
    fragment pagesHeaderFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "join-us-freelancer"}}) {
            frontmatter {
                title
                description
                
                mainTitle
                secondaryTitle

                introText
                joinUsTitle
                joinUsPart1Text
                joinUsPart2Text
                joinUsPart3Text
                joinUsPart4Text
                joinUsPart5Text
                joinUsPart6Text

                formSurnameLabel
                formInvalidSurnameText
                formFirstNameLabel
                formInvalidFirstNameText
                formEmailLabel
                formEmailPlaceHolder
                formInvalidEmailText
                formTelephoneLabel
                formCVLabel
                formCVFileTypeText
                formCVFileSizeText
                formInvalidCVFileText
                formJobLabel
                formAvailabilityLabel
                formAvailabilityPlaceHolder
                formTJMLabel
                formTravelLabel
                formRemarksLabel
                formButtonText
                formButtonSrOnly
                formRequiredFieldsText

                contactButtonText
            
                invalidFieldsToastPart1Text
                invalidFieldsToastPart2Text
            
                successToastPart1Text
                successToastPart2Text
            
                errorToastPart1Text
                errorToastPart2Text
            }
        }
        
        datArtJoinUsHeaderImage: file(relativePath: {regex: "/datArtJoinUsHeaderImage/"}) {
            ...pagesHeaderFluidImage
        }
    }
`;
